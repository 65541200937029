import React from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {Container, Card, CardContent, makeStyles, Grid, TextField, Button} from '@material-ui/core';

function Barcode5() {
  
    
   
      const [data, setData] = React.useState("Not Found");
    
      return (
        <>
        <div style={{display:'block'}}>
        <p>Barcode Scanner</p>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <BarcodeScannerComponent
            width={400}
            height={400}
            constraints={{
                facingMode: 'environment'
            }}
            onUpdate={(err, result) => {
              if (result) 
              {
              setData(result.text)
              alert(result.text)
              }
            //   ;
            //   else setData("Not Found");
            }}
          />
          </Grid>
          <p>{data}</p>
          </div>
        </>
      );
    
}
export default Barcode5