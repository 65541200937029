import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate,Link, useParams } from 'react-router-dom'

function AddProduct() {
   const navigate=useNavigate()
   const [cabang,setCabang]=useState([])
   const [cabangres,setCabangres]=useState('')

   const [kode,setKode]=useState('')
   const [nama,setNama]=useState('')
   const [hjual,setHJual]=useState('')
   const [hbeli,setHBeli]=useState('')
   const [namafile,setNamafile]=useState('')
   const {id}=useParams()


   useEffect(()=>{
getCabang();
   },[])

   const getCabang=async ()=>{
    const res=await axios.get('https://dialey.angsoft.info/basereact/listcabang.php')
    try{
       setCabang(res.data)
      
       console.log(res.data)

    }catch(err)
    {
console.log(err)
    }

   }

   

    const postProduct=async (a)=>{
        a.preventDefault()

        const formData=new FormData()
        formData.append('kode',kode)
        formData.append('nama',nama)
        formData.append('h_jual',hjual)
        formData.append('h_beli',hbeli)
        formData.append('cabang',cabangres)
        formData.append('pfile',namafile)
     

      const res=await axios.post('https://dialey.angsoft.info/basereact/product.php',formData,
      
      {headers:{'Content-Type':"multipart/form-data"}}
      )

      try{
        console.log(res.data.success)   
        navigate('/ListProduct')
      }catch(err)
      {
        console.log(err)
      }

    }

  return (
    <>
    
    <div className='container'>
    <h2>Add Product</h2><Link to={'/ListProduct'} className='btn btn-warning'>Back</Link>
    <br/>
      <form onSubmit={postProduct}>
      
      <div className='col-md-12'>
    
      <div className='row'>
      <label className='col-sm-2 fs-4 fw-bold'>Kode Product</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  className='form-control' placeholder='Kode Product' value={kode} onChange={(a)=>setKode(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Nama Product</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  className='form-control' placeholder='Nama Product' value={nama} onChange={(a)=>setNama(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Harga Jual</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  value={hjual} onChange={(a)=>setHJual(a.target.value)} className='form-control' placeholder='Harga Jual'/>
      </div>
      </div>
      
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Harga Beli</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text' value={hbeli} onChange={(a)=>setHBeli(a.target.value)}  className='form-control' placeholder='Harga Beli'/>
      </div>
      </div>

      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Cabang</label> 
      <div className='col-sm-8 fs-2'>
        
      <select className='form-control' value={cabangres} onChange={(a)=>setCabangres(a.target.value)}  > 
       {cabang.map((data,i)=>{
       return <option value={data.kodecabang}>{data.nama}</option>
       })
       }
      </select>
      </div>
      </div>


      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Picture</label> 
      <div className='col-sm-8 fs-2'>
      <input type='file'  className='form-control' onChange={(a)=>setNamafile(a.target.files[0])} />
      </div>
      </div>

      <div className='row mb-2 mt-4'>
      <button className='btn btn-success col-sm-2 fs-3'>Save</button>
      </div>

      </div>


 


      </form>
    </div>
    
    </>
  )
}

export default AddProduct