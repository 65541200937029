import React from 'react'
import reactlogo from './poslogonew.png'
import './irawan.css'
 

function Home() {
  return (
    <div className='container col-md-12'>
    <div className='row'>
    <div className='col-sm-12'>  
    <div className='homelogo' >
    <img src={reactlogo} width='100px' height='50px'></img>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Home