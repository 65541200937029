import React, {useState, useRef} from 'react';
import {Container, Card, CardContent, makeStyles, Grid, TextField, Button} from '@material-ui/core';
import QRCode from 'qrcode';
import QRCode2 from 'react-qr-code';

function Barcode3() {
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
 
  const classes = useStyles();
  const qrRef = useRef(null);


  const generateQrCode = async () => {
    try {
          const response = await QRCode.toDataURL(text);
          setImageUrl(response);
    }catch (error) {
      console.log(error);
    }
  }
  
  return (
    <Container className={classes.container}>
    <Card>
        <h2 className={classes.title}>Generate Download & Scan QR Code with React js</h2>
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <TextField label="Enter Text Here" onChange={(e) => setText(e.target.value)}/>
                    <Button className={classes.btn} variant="contained" 
                      color="primary" onClick={() => generateQrCode()}>Generate</Button>
                      <br/>
                      <br/>
                      <br/>
                      {imageUrl ? (
                        <a href={imageUrl} download>
                            <img src={imageUrl} alt="img"/>
                        </a>) : null}
                </Grid>
      

                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <TextField label="Enter Text Here" onChange={(e) => setText(e.target.value)}/>
                    <Button className={classes.btn} variant="contained" 
                      color="primary" onClick={() => generateQrCode()}>Generate</Button>
                      <br/>
                      <br/>
                      <br/>
                      <QRCode2
title={text}
value={text}
// bgColor="background-color"
// fgcolor="foreground-color"
// level="level"
size={150}
/>
                </Grid>
                
            </Grid>
        </CardContent>
    </Card>
</Container>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:  'center',
    background: '#3f51b5',
    color: '#fff',
    padding: 20
  },
  btn : {
    marginTop: 10,
    marginBottom: 20
  }
}));

export default Barcode3