// import "./App.css";
 
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import { BrowserRouter,Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
import SidebarMenu from "./posheru/SidebarMenu";
import 'bootstrap/js/dist/dropdown'
import ListProduct from "./posheru/MasterItem/ListProduct";
import Customer from "./posheru/MasterItem/Customer";
import Cabang from "./posheru/MasterItem/Cabang";
import AddProduct from "./posheru/MasterItem/AddProduct";
import EditProduct from "./posheru/MasterItem/EditProduct";
import Header from "./posheru/Header";
import Home from "./posheru/Home";
import PrintListProduct from "./posheru/MasterItem/PrintListProduct";
import EditProductPrint from "./posheru/MasterItem/EditProductPrint";
import ListCustomer from "./posheru/MasterCust/ListCust";
import EditCust from "./posheru/MasterCust/EditCust";
import AddCust from "./posheru/MasterCust/AddCust";
import PrintListCust from "./posheru/MasterCust/PrintListCust";
import EditCustPrint from "./posheru/MasterCust/EditCustPrint";
import Barcode from "./posheru/Barcode";
import Barcode2 from "./posheru/Barcode2";
import Barcode3 from "./posheru/Barcode3";
import Barcode4b from "./posheru/Barcode4";
import Barcode5 from "./posheru/Barcode5";
import Barcode6 from "./posheru/Barcode6";
import PosTrans from "./posheru/Transaksi/PosTrans";



function App() {
  return (
    <BrowserRouter>
     <Header/>
    <div className="d-flex">
    
   
       <Routes>
        <Route path="/" element={<Home/>}  />
        <Route path="/ListProduct" element={<ListProduct/>}/>
        <Route path="/Customer" element={<Customer/>}/>
        <Route path="/Cabang" element={<Cabang/>}/>
        <Route path="/AddProduct" element={<AddProduct/>}/>
        <Route path="/EditProduct/:id" element={<EditProduct/>}/>
        <Route path="/EditProductPrint/:id" element={<EditProductPrint/>}/>
        <Route path="/PrintListProduct" element={<PrintListProduct/>}/>
        <Route path="/ListCustomer" element={<ListCustomer/>}/>
        <Route path="/EditCust/:id" element={<EditCust/>}/>
        <Route path='/AddCust' element={<AddCust/>}/>
        <Route path='/PrintListCust' element={<PrintListCust/>}/>
        <Route path="/EditCustPrint/:id" element={<EditCustPrint/>} />
        <Route path="/Barcode" element={<Barcode/>} />
        <Route path="/Barcode2" element={<Barcode2/>} />
        <Route path="/Barcode3" element={<Barcode3/>} />
        <Route path="/Barcode4b" element={<Barcode4b/>} />
        <Route path="/Barcode5" element={<Barcode5/>} />
        <Route path="/Barcode6" element={<Barcode6/>} />
        <Route path="/PosTrans" element={<PosTrans/>} />
       </Routes>

  
 
    </div>

    </BrowserRouter>
  );
}

export default App;


