import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {useNavigate, useParams,Link} from 'react-router-dom'
// import { BorderAll } from '@material-ui/icons'
import ScaleLoader from 'react-spinners/ScaleLoader'
import PuffLoader from 'react-spinners/PuffLoader'
import nopict from '../image/nopict.gif'


function EditProduct() {
    const navigate=useNavigate()
    const [cabang,setCabang]=useState([])
    const [cabangres,setCabangres]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const [isLoadingPict,setIsLoadingPict]=useState(false)
 
    const [kode,setKode]=useState('')
    const [nama,setNama]=useState('')
    const [hjual,setHJual]=useState('')
    const [hbeli,setHBeli]=useState('')
    const [namafile,setNamafile]=useState('')
    const [namafile_b,setNamafile_b]=useState('')
    const {id}=useParams()
    const [detailProd,setDetailProd]=useState({id:'',kode:'',nama:'',harga_jual:'',harga_beli:'',cabang:'',disc_val:'',namafile:''})


 
    useEffect(()=>{
        getdetailProd()
        getCabang()
      },[])
    
      const getdetailProd=async()=>{
        setIsLoading(true)
        setIsLoadingPict(true)

        const res=await axios.get("https://dialey.angsoft.info/basereact/product.php/"+id)
        try{
            // setDetailProd(res.data)
        setKode(res.data.kode)
        setNama(res.data.nama)
        setHJual(res.data.harga_jual)
        setHBeli(res.data.harga_beli)
        setCabangres(res.data.cabang)
        setNamafile(res.data.namafile)

        // console.log(namafile)
        }catch(err)
        {
            console.log(err)
        }

        setIsLoading(false)
        setIsLoadingPict(false)
    
      }


      const getCabang=async ()=>{
        const res=await axios.get('https://dialey.angsoft.info/basereact/listcabang.php')
        try{
           setCabang(res.data)
          
           console.log(res.data)
    
        }catch(err)
        {
    console.log(err)
        }
    
       }
    

    const postProduct=async (a)=>{
        a.preventDefault()
setIsLoading(true)
        const formData=new FormData()
        formData.append('kode',kode)
        formData.append('nama',nama)
        formData.append('h_jual',hjual)
        formData.append('h_beli',hbeli)
        formData.append('cabang',cabangres)
        formData.append('pfile',namafile_b)
        formData.append('tipepost','1')
        formData.append('id',id)

      const res=await axios.post('https://dialey.angsoft.info/basereact/product.php',formData,
      
      {headers:{'Content-Type':"multipart/form-data"}}
      )

      try{
        console.log(res.data.success)   
        navigate('/ListProduct')
      }catch(err)
      {
        console.log(err)
      }
setIsLoading(false)
    }

  return (
    <>
    <div className='container'>
    <div className='loaderlist'>
    {isLoading?<PuffLoader  cssOverride={{marginBlock:'10rem',width:'20%'}}  />:''}
    </div>

    <h2>Edit Product</h2><Link to='/ListProduct' className='btn btn-warning'>Back</Link>
    <br/>
 
      <form onSubmit={postProduct}>
  
      <div className='col-md-12'>
    
      <div className='row'>
      <label className='col-sm-2 fs-4 fw-bold'>Kode Product</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  className='form-control' placeholder='Kode Product' value={kode} onChange={(a)=>setKode(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Nama Product</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  className='form-control' placeholder='Nama Product' value={nama} onChange={(a)=>setNama(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Harga Jual</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  value={hjual} onChange={(a)=>setHJual(a.target.value)} className='form-control' placeholder='Harga Jual'/>
      </div>
      </div>
      
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Harga Beli</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text' value={hbeli} onChange={(a)=>setHBeli(a.target.value)}  className='form-control' placeholder='Harga Beli'/>
      </div>
      </div>

      <div class="form-group"> 
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Cabang</label> 
      <div className='col-sm-8 fs-2'>
        
      
      <select  value={cabangres}  onChange={(a)=>setCabangres(a.target.value)}  > 
       {cabang.map((data,i)=>{
       return <option value={data.kodecabang}>{data.nama}</option>
       })
       }
      </select>
      </div>
      </div>
      </div>


      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Picture</label> 
      <div className='col-sm-8 fs-2'>
      <input type='file'  className='form-control' onChange={(a)=>setNamafile_b(a.target.files[0])} />
      <div className='col-sm-1'>
      {!namafile?<img src={nopict}/>:isLoadingPict?<ScaleLoader size='1px'/>:<img style={{marginTop:'2px',Border:'1px solid black'}} src={'https://dialey.angsoft.info/upload_files/'+namafile} width='100px' height='100px'/>}
      </div>
      </div>
      </div>

      <div className='row mb-2 mt-4'>
      <button className='btn btn-success col-sm-2 fs-3'>{isLoading?<ScaleLoader size='1px'/>:'Save'}</button>
      </div>

      </div>


 


      </form>
    </div>
    
    
    </>
  )
}

export default EditProduct