import React from 'react'
import { Link,NavLink } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/collapse'
import reactlogo from './poslogonew2.gif'
import './irawan.css'
import ListCustomer from './MasterCust/ListCust'

function Header() {

const element=document.querySelector('a.dropdown-link');
// element.addEventListener('click',function(){
// alert('click');
// })

document.addEventListener('click',(e)=>{

  if (e.target.className==='dropdown-item')
  {
      document.querySelector('.navbar-toggler').classList.add('collapsed')
      
      document.querySelector('.navbar-collapse').classList.remove('show')
      document.querySelector('.dropdown-menu').classList.remove('show')
  }
  console.log(e.target.className)
})


  return (
    <>
    

      
    
<nav className="navbar navbar-expand-lg bg-warning">
  <div className="container">
     <div style={{display:'flex',verticalAlign:'middle',alignItems:'center'}}>
     <Link to='/' className="navbar-brand" ><img src={reactlogo} width='100px' height='30px' style={{borderRadius:'10px'}}/></Link> 
     <span style={{fontSize:'10px',marginRight:'20px'}} id=''versi_prod>V.1.8.21</span>
     </div>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink to='/' className="nav-link" aria-current="page" >Home</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to='/userlist' className="nav-link " aria-current="page" >User List</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to='/adduser' className="nav-link" aria-current="page" >Add User</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to='/product' className="nav-link" aria-current="page" >Product List</NavLink>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li> */}
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bind="enable: !noResults()">
            Master
          </a>
          <ul className="dropdown-menu">
          {/* <li><Link to='/Barcode2' className="dropdown-item" href="#">Barcode</Link></li> */}
            <li><Link to='/ListProduct' className="dropdown-item" href="#">Product</Link></li>
            <li><Link to="/ListCustomer" className="dropdown-item" href="#">Customer</Link></li>
         
            <li><a className="dropdown-item" href="#">GL Module</a></li>
          </ul>


          
        </li> 

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bind="enable: !noResults()">
            Barcode
          </a>
          <ul className="dropdown-menu">
          <li><Link to='/Barcode3' className="dropdown-item" href="#">Barcode QR CODE</Link></li>
        
          <li><Link to='/Barcode6' className="dropdown-item" href="#">Scan Barcode</Link></li>
      
          </ul>
        </li> 

        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bind="enable: !noResults()">
            POS
          </a>
          <ul className="dropdown-menu">
          <li><Link to='/PosTrans' className="dropdown-item" href="#">POS Entry</Link></li>
        
         
      
          </ul>
        </li> 
           {/* <li className="nav-item">
          <a className="nav-link disabled" aria-disabled="true">Disabled</a>
        </li> */}
      </ul>
      {/* <form className="d-flex" role="search">
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit">Search</button>
      </form> */}
    </div>
  </div>
  
</nav>

    </>
  )
}

export default Header