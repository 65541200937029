import React, { useEffect, useRef, useState } from "react";
// To use Html5QrcodeScanner (more info below)
// To use Html5QrcodeScanner (more info below)
import { Html5QrcodeScanner } from "html5-qrcode";
import {Modal} from 'react-responsive-modal'
import Barcode2_scan from "./Barcode2_scan";


// To use Html5Qrcode (more info below)
import { Html5Qrcode } from "html5-qrcode";
import axios from "axios";

function Barcode2(props) {
  const [isbarcode, setBarcode] = useState([]);
  const [scanResult,setScanResult]=useState([])
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  let hasil2;

  useEffect(()=>{
    
// alert('hasiul2'+props.barcodehasil)
    
  })


  const closeIcon = (
    <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
      <path
        fillRule="evenodd"
        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
  


  useEffect(() => {
    lihatdata();
  }, []);

  const lihatdata = async () => {
    const res = await axios.get(
      "https://dialey.angsoft.info/basereact/barcode.php"
    );

    try {
      if (res.data)
      {setBarcode(res.data);
        console.log("isi barcode" + res.data);}else{
          setBarcode(kosong)
        }
      
    } catch (err) {
      console.warn(err);
    }
  };

const kosong=[{
  barcode:'-',
}]
  return (
    <>
      <div className="cont_barcode">
       
          <div className="vertical_center">
            <div style={{display:'block',margin:'0 auto'}}>
              <button
                style={{
                  fontSize: "30%",
                  marginTop: "10px",
                  marginBottom: "5px",
                  width:'auto',
                  textAlign:'center'
                }}

                onClick={onOpenModal}
              >
                Scan
              </button>  


              <Modal open={open} onClose={onCloseModal } 
              
            
              center>
        <h2 style={{marginBottom:"5px"}}>hasil {props.barcodehasil}</h2>
 
<Barcode2_scan />

      </Modal>


              <table style={{fontSize:'12px'}}>
                <tr>
                  <th style={{backgroundColor:'blue',color:'white',padding:'2px'}}>Barcode List</th>
                </tr>
                {isbarcode.map((b, i) => {
                  return (
                    <tr key={i} style={{textAlign:'center'}}>
                      <td>{b.barcode}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
    
    </>
  );
}
export default Barcode2;
