import React, { useEffect, useRef, useState } from 'react'
// To use Html5QrcodeScanner (more info below)
// To use Html5QrcodeScanner (more info below)
import {Html5QrcodeScanner} from "html5-qrcode";

// To use Html5Qrcode (more info below)
import {Html5Qrcode} from "html5-qrcode";
import axios from 'axios';
import beep from './sound/beep.wav'
import Barcode2 from './Barcode2';

function Barcode2_scan() {

    

    // const html5QrCode = new Html5Qrcode("reader");
    // const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    //     /* handle success */
    // };
    // const config = { fps: 10, qrbox: { width: 250, height: 250 } };
    
    // // If you want to prefer front camera
    // html5QrCode.start({ facingMode: "user" }, config, qrCodeSuccessCallback);
    
    // // If you want to prefer back camera
    // html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
    
    // // Select front camera or fail with `OverconstrainedError`.
    // html5QrCode.start({ facingMode: { exact: "user"} }, config, qrCodeSuccessCallback);
    
    // Select back camera or fail with `OverconstrainedError`.
    // html5QrCode.start({ facingMode: { exact: "environment"} }, config, qrCodeSuccessCallback);

      //   let html5QrCode;
   

      //   useEffect(() => {
      //     // Anything in here is fired on component mount.
         
      //       html5QrCode = new Html5Qrcode('reader');
      //         const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      //             /* handle success */
      //         };
      //         const config = { fps: 10, qrbox: { width: 250, height: 250 }, aspectRatio: 1.777778};
  
      //         // If you want to prefer back camera
      //         html5QrCode.start(
      //             { facingMode: "environment" },
      //             config,
      //             qrCodeSuccessCallback
      //         );
      //     }
  
      //     return () => {
      //         // Anything in here is fired on component unmount.
  
      //     };
      // }, []);
      
        
   const [scanResult,setScanResult]=useState([])
   const [jum,setJum]=useState([])
   const[isbarcode,setBarcode]=useState([])


   let song = new Audio(beep);



let scanner;
let a=[];
let b;
let hasil;
   useEffect(()=>{

  //  const  Html5QrcodeScanner = new Html5QrcodeScanner('reader');

  if(!scanner?.getState()){
    scanner=new Html5QrcodeScanner('reader',{
      qrbox:{
        width:250,
        height:250,
      },
      rememberLastUsedCamera: true,
      fps:10,
      // showTorchButtonIfSupported: true
       });


      // scanner.start({ facingMode: "environment" }, success,error);

      
      
       scanner.render(success,error);
      
       function success(result){
        // scanner.clear();
    //  alert(result)
        setScanResult(result);
        hasil=result
      alert(hasil)
        song.play();
        setTimeout(()=>{
          song.pause();
        },1000)
        // song.pause();
       }
      
       function error(err){
      console.warn(err);
       }
          

      }

   },[])

 

   useEffect(()=>{
tambahdata()
 


   },[scanResult])
 
 

const tambahdata=async()=>{
  const formData=new FormData()
  formData.append('barcode',scanResult)



  const res=await axios.post('https://dialey.angsoft.info/basereact/barcode.php',formData)

  try{

    console.log(res.data)
  }catch(err)
  {
console.warn(err)
  }

}



 
  return (
     <>
      
    <div id="reader" ></div>
 

    </>  
  )
 
  }
export default Barcode2_scan