import React, { useEffect, useRef, useState } from 'react'
import {UseReactToPrint, useReactToPrint} from 'react-to-print'
import PuffLoader from 'react-spinners/PuffLoader'
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { NumericFormat } from 'react-number-format'
import nopict from '../image/nopict.gif'
import ScaleLoader from 'react-spinners/ScaleLoader'

function PrintListProduct() {
    const componentRef=useRef()
    const [data,setData]=useState([])
    const [isLoading,setIsLoading]=useState(false)
    const [isLoadingPict,setIsLoadingPict]=useState(false)
    const navigate=useNavigate()

    const handlePrint=useReactToPrint({
        content:()=>componentRef.current,
        documentTitle:'Judul',
        // onAfterPrint:()=>alert("Data Saved")
    })

    useEffect(()=>{
    
        getList()
       
      
    },[])


  const getList=(async()=>{
    setIsLoading(true)
    setIsLoadingPict(true)
    const res =await axios.get('https://dialey.angsoft.info/basereact/product.php')
    try{
     setData(res.data)
    }catch(err)
    {
        console.log(err)
    }

   setTimeout(()=>{
    // handlePrint()
    setIsLoading(false)
    setIsLoadingPict(false)
   },1000)
    
    
  })
   
    

  return (
 
    <div className='container'>
        <div className='row'>
       <div className='col-sm-5 mt-2 mb-2'>
        
    <button  onClick={()=>navigate('/ListProduct')  }  style={{fontSize:'8px',marginLeft:'5px'}}>Back</button>
    <button  onClick={()=>handlePrint() }  style={{fontSize:'8px',marginLeft:'5px'}}>Print</button>

         </div>
    <div className='loaderlist'>
    {isLoading?<PuffLoader  cssOverride={{marginBlock:'10rem',width:'20%'}}  />:''}

    </div>

    
    <div className='col-sm-12'>

    <div ref={componentRef} style={{marginTop:'50px',marginLeft:'50px',marginRight:'10px',marginBottom:'10px'}}>
       <p style={{marginLeft:'10px'}}> Print List Product</p>
     <table id='printProduct'>
     <thead>
     <tr><th>Kode</th><th>Nama</th><th>Harga Jual</th><th>Harga Beli</th><th>Cabang</th><th>Picture</th></tr>
     </thead>    
    <tbody>
        {data.map((d,i)=>{
            return (
    <tr key={i}>   
     <td>{d.kode}</td>   
     <td>{d.nama}</td>
     <td><NumericFormat value={d.harga_jual} displayType={'text'} thousandSeparator={true} prefix='' /></td>
     <td><NumericFormat value={d.harga_beli} displayType={'text'} thousandSeparator={true} prefix=''/></td>
     <td>{d.cabang}</td>
     <td >{!d.namafile ? <img src={nopict} width='50px' height='50px' /> : isLoadingPict?<ScaleLoader size='1px'/>:<img src={'https://dialey.angsoft.info/upload_files/'+d.namafile} width='50px' height='50px'/>}</td>
 
    </tr>
    )
})
}
    </tbody>
    
    
    </table>   
    </div>
    </div>
    </div>
    </div>
  )
}

export default PrintListProduct