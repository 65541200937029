import React,{useEffect, useState} from 'react'
import axios from 'axios'
import {useNavigate, useParams,Link} from 'react-router-dom'
// import { BorderAll } from '@material-ui/icons'
import ScaleLoader from 'react-spinners/ScaleLoader'
import PuffLoader from 'react-spinners/PuffLoader'
import nopict from '../image/nopict.gif'


function AddCust() {
    const navigate=useNavigate()
    const [cabang,setCabang]=useState([])
    const [cabangres,setCabangres]=useState('')
    const [isLoading,setIsLoading]=useState(false)
    const [isLoadingPict,setIsLoadingPict]=useState(false)
 
    const [kode,setKode]=useState('')
    const [nama,setNama]=useState('')
    const [nohp,setNohp]=useState('')
    const [alamat,setAlamat]=useState('')

    const [hjual,setHJual]=useState('')
    const [hbeli,setHBeli]=useState('')
    const [namafile,setNamafile]=useState('')
    const [namafile_b,setNamafile_b]=useState('')
    const {id}=useParams()
    const [detailProd,setDetailProd]=useState({id:'',kode:'',nama:'',harga_jual:'',harga_beli:'',cabang:'',disc_val:'',namafile:''})


 
    useEffect(()=>{
        getdetailProd()
        // getCabang()
      },[])
    
      const getdetailProd=async()=>{
        setIsLoading(true)
        setIsLoadingPict(true)

        const res=await axios.get("https://dialey.angsoft.info/basereact/customer.php/"+id)
        try{
            // setDetailProd(res.data)
        setKode(res.data.custid)
        setNama(res.data.custname)
        setNohp(res.data.nohp)
        setAlamat(res.data.alamat)
        
        console.log(res.data)
        }catch(err)
        {
            console.log(err)
        }

        setIsLoading(false)
        setIsLoadingPict(false)
    
      }


      const getCabang=async ()=>{
        const res=await axios.get('https://dialey.angsoft.info/basereact/listcabang.php')
        try{
           setCabang(res.data)
          
           console.log(res.data)
    
        }catch(err)
        {
    console.log(err)
        }
    
       }
    

    const postProduct=async (a)=>{
        a.preventDefault()
setIsLoading(true)
        const formData=new FormData()
        formData.append('kode',kode)
        formData.append('nama',nama)
        formData.append('nohp',nohp)
        formData.append('alamat',alamat)
        // formData.append('id',id)

      const res=await axios.post('https://dialey.angsoft.info/basereact/customer.php',formData,
      {headers:{'Content-Type':"multipart/form-data"}}

      // const res=await axios.post('https://dialey.angsoft.info/basereact/customer.php',{kode,nama,alamat,nohp,id}

      )

      try{
        console.log('data success'+res.data.success)   
        navigate('/ListCustomer')
      }catch(err)
      {
        console.log(err)
      }
setIsLoading(false)
    }

  return (
    <>
    <div className='container'>
    <div className='loaderlist'>
    {isLoading?<PuffLoader  cssOverride={{marginBlock:'10rem',width:'20%'}}  />:''}
    </div>

    <h2>Add Customer</h2><Link to='/ListCustomer' className='btn btn-warning'>Back</Link>
    <br/>
 
      <form onSubmit={postProduct}>
  
      <div className='col-md-12'>
    
      <div className='row'>
      <label className='col-sm-2 fs-4 fw-bold'>Kode Cust.</label> 
      <div className='col-sm-8 fs-2'>
      <input  type='text'  className='form-control' placeholder='Kode Product' value={kode} onChange={(a)=>setKode(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Nama Customer</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  className='form-control' placeholder='Nama Customer' value={nama} onChange={(a)=>setNama(a.target.value)}/>
      </div>
      </div>
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>NO. HP</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text'  value={nohp} onChange={(a)=>setNohp(a.target.value)} className='form-control' placeholder='No. HP'/>
      </div>
      </div>
      
      
      <div className='row mb-2 mt-2'>
      <label className='col-sm-2 fs-4 fw-bold'>Alamat</label> 
      <div className='col-sm-8 fs-2'>
      <input type='text' value={alamat} onChange={(a)=>setAlamat(a.target.value)}  className='form-control' placeholder='Alamat'/>
      </div>
      </div>

       
        
      
       
   


  

      <div className='row mb-2 mt-4'>
      <button className='btn btn-success col-sm-2 fs-3'>{isLoading?<ScaleLoader size='1px'/>:'Save'}</button>
      </div>

      </div>


 


      </form>
    </div>
    
    
    </>
  )
}

export default AddCust