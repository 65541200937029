import {ArrowBackRounded , Person4, QrCodeScanner, Stop,RestoreIcon,FavoriteIcon,LocationOnIcon,AbcTwoTone    } from "@mui/icons-material";
import { AppBar, Avatar, Box, CssBaseline, Fab, Toolbar, Typography,BottomNavigationAction,BottomNavigation} from "@mui/material";
import QrScanner from "qr-scanner";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Puffloader from "react-spinners/PuffLoader";
import bell from '../sound/bell.mpeg'
import { useNavigate } from "react-router-dom";
import { BsFillPersonVcardFill } from "react-icons/bs";
 

let stopScan = false;
let hasilScan = "";

function PosTrans() {
    const [btnScan, setBtnScan] = useState(true);
    const [value, setValue] = React.useState(0);
    const [isbarcode, setBarcode] = useState([]);
    const [isTambah, setIsTambah] = useState([]);
    const [isLoading,setIsLoading]=useState(false);

    let song = new Audio(bell);

    const navigate=useNavigate();


    // TambahData

 
       useEffect(()=>{
//tambahdata()
       },[isTambah])
      
      const tambahdata=async()=>{
      
        const formData=new FormData()
    
       

        formData.append('barcode',isTambah)
      
      
      
        const res=await axios.post('https://dialey.angsoft.info/basereact/barcode.php',formData)
      
        try{
    
          song.play();
          setTimeout(()=>{
            song.pause();
          },1000)
lihatdata()
          console.log(res.data)
        }catch(err)
        {
      console.warn(err)
        }
      setIsLoading(false)
      }
      
      

    
    // Lihat Data

    useEffect(() => {
      lihatdata();
   
    
    }, [isTambah]);
  
    const lihatdata = async () => {
      setIsLoading(true)
      const res = await axios.get(
        "https://dialey.angsoft.info/basereact/barcode.php"
      );
  
      try {
        if (res.data)
        {setBarcode(res.data);
          console.log("isi barcode" + res.data);}else{
            setBarcode(kosong)
          }
        
      } catch (err) {
        console.warn(err);
      }
      setIsLoading(false)
    };

const kosong=[{
  barcode:'-'
}]
    const scanNow = async (isScan) => {
      setBtnScan(isScan)
      if (isScan) stopScan = true
      if (btnScan === false) return
      stopScan = false
      await new Promise(r => setTimeout(r, 100))
      const videoElement = document.getElementById('scanView')
      const scanner = new QrScanner(
        videoElement,
        result => {
          hasilScan = result.data
        
       
 setIsTambah(result.data)
      
          setBtnScan(true)
          stopScan = true
        },
        {
          onDecodeError: error => {
            console.error(error)
          },
          maxScansPerSecond: 1,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          returnDetailedScanResult: true
        }
      )
      await scanner.start()
      while (stopScan === false) await new Promise(r => setTimeout(r, 100))
      scanner.stop()
      scanner.destroy()
    }
  
    return (
      <React.Fragment>
        {isLoading?<Puffloader size='12px' cssOverride={{marginBlock:'1rem'}}/>:''}

        <CssBaseline />
        <AppBar>
          <Toolbar>
          <Avatar sx={{  bgcolor: "blue",cursor:'pointer' }}>
              <ArrowBackRounded onClick={()=>navigate('/')}/>
 
            </Avatar>
            <Avatar sx={{ mr: 1, bgcolor: "secondary.main" }}>
              <Person4 />
             
            </Avatar>
            <Typography variant="h6">POS Barcode</Typography>
         
           
   

          </Toolbar>
        </AppBar>
        <div className="container">
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            paddingTop: "64px",
          }}
        >
          {btnScan === false && (
            <video
              id="scanView"
              style={{
                width: "100%",
                height: "100%",
                borderStyle: "dotted",
              }}
            ></video>
          )}
          {btnScan && (
            <Typography variant="h6">
              
            
              <table id='tabpos1'>
                <tr>
                  <td>Customer </td><td><span   style={{fontSize:'20px',cursor:'pointer'}} onClick={()=>(alert('test'))}><BsFillPersonVcardFill/></span></td>
                   
                </tr>
                <tr><td>.</td></tr>
              
                <tr>  
                  <th style={{width:'150px'}}>Name</th><th style={{width:'30px'}}>Qty</th><th style={{width:'70px'}}>Price</th><th style={{width:'70px'}}>Disc.</th>
                </tr>
               <tr style={{height:'300px'}}><td>x</td></tr>
                
                <tr style={{bottom:'0px'}}>
                  <th colSpan={1}>Sub Total</th><th colSpan={3}>Rp. </th>
                </tr>
              
               
               
              </table>
              

            </Typography>
          )}
        </Box>
        </div>
        <br />
             
        <Fab
          color={btnScan ? "primary" : "secondary"}
          onClick={() => scanNow(!btnScan)}
          sx={{ position: "absolute", bottom: 16, right: 16 }}
        >
          {btnScan && <QrCodeScanner />}
          {btnScan === false && <Stop />}
        </Fab>



   
      </React.Fragment>
    );
}

export default PosTrans