import React, { useEffect, useState } from 'react'
import  './ModalComp.css'
import { read, utils, writeFile } from 'xlsx';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import {Link, useParams} from 'react-router-dom'
import ListProduct from './ListProduct';
 

function ExcelModal({setOpenModal}) {
  // const [products, setProducts] = useState({kode:"",nama:"",harga_jual:"",harga_beli:"",cabang:""});
  const [products, setProducts] = useState([]);

  const [cabang,setCabang]=useState([])
  const [kode,setKode]=useState([])
  const [nama,setNama]=useState([])
  const [hjual,setHJual]=useState([])
  const [hbeli,setHBeli]=useState([])
  const [products2, setProducts2] = useState([]);
  const [message,setMessage]=useState('')
  const navigate=useNavigate()
  const [refresh,setRefresh]=useState(false)
  
  
   

  const handleImport = ($event) => {
      const files = $event.target.files;
      if (files.length) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
              const wb = read(event.target.result);
              const sheets = wb.SheetNames;

              if (sheets.length) {
                  const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                  setProducts(rows)
                  // sendData()
                  // console.log('log baru '+JSON.stringify(rows[0]))
                  
              }
          }
          reader.readAsArrayBuffer(file);
      }
  } 



const customConfig = {
  headers: {
  'Content-Type': 'application/json'
  }
};

const awal= () => window.location.reload(true)

const customConfig2 = {
  headers:{'Content-Type':"multipart/form-data"}
  
};

useEffect(()=>{
  sendData()
},[products])
  const sendData=async ()=>{

      // const formData=new FormData()
    
      //   formData.append('kode',kode1)
      //  formData.append('nama',products.nama)
      //  formData.append('h_jual',products.harga_jual)
      //  formData.append('h_beli',products.harga_beli)
      //  formData.append('cabang',products.cabang)

    // console.log('yang baru'+JSON.stringify(products))
    const res=await axios.post('https://dialey.angsoft.info/basereact/insertjson.php',JSON.stringify(products),customConfig)
    try{
        // console.log(res.data.success)
        setMessage(res.data.success)
  

      // res.data.success==='success' ? navigate('/ListProduct'):''

    }catch(err)
    {
      console.log(err)
    }
   }
   const [lempar,setLempar]=useState()

  return (
    // <div className='modalBackground'> 
    <div className='modalContainer'>
 
      <div className='title' >
      <h1>Excel Upload </h1>
      <h2>{message} </h2>
      </div>
      <div className='body'>
        <div style={{display:'block',justifyContent:'center'}}>
      <div className="row">
                        <div className="col">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    {/* <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label> */}
                                </div>
                            </div>
                        </div>
      </div>


      
            

            </div>
      </div>
      {/* <div className='footer'> */}
      {/* { refresh && <ListProduct bedul={'ok'}/>} */}
        {/* <button id='cancelBtn' onClick={() => {
              setOpenModal(false);
            sessionStorage.setItem('keyItem','lempar')
           
            }}>Close</button> */}
    
       {/* <Link to='/ListProduct' className='btn btn-success'>Close</Link> */}
       {/* <button onClick={awal}>Close</button> */}
     
      {/* </div> */}
       

    </div>

    // </div>
  )
}

export default ExcelModal