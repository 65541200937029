import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";

import DataTable from "react-data-table-component";
import { AiFillEdit, AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import nopict from '../image/nopict.gif';
 
import {
  FaPrint,
  FaRegCalendarPlus,
  FaRecordVinyl,
  FaFileImport,
  FaFileExport,
  FaCloudDownloadAlt,
  FaCut,
} from "react-icons/fa";
import Swal from "sweetalert2";
import "../irawan.css";
import ExcelModal from "./ExcelModal";
import Puffloader from "react-spinners/PuffLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import ReactPaginate from "react-paginate";
import "./ModalComp.css";
import { read, utils, writeFile } from "xlsx";
// import {KeyboardDoubleArrowDownIcon,FormatClearIcon} from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import PrintListProduct from "./PrintListProduct";

//import Modal from 'react-modal'\\\
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


import "/node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "/node_modules/datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "/node_modules/datatables.net-bs5/css/dataTables.bootstrap5.css";
 

import "datatables.net-bs5";
// require("datatables.net-bs5")()

var $ = require("jquery");
$.DataTable = require('datatables.net')



function ListProduct({ lempar }) {

  const [open, setOpen] = useState(false)

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onCloseModal2=()=>{
    onCloseModal();
    sessionStorage.setItem('keyItem','lempar');
  }

  const [openModal, setOpenModal] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isLoadingPict,setIsLoadingPict]=useState(false)
  const { id } = useParams();
  const [detailProd, setDetailProd] = useState([]);
  const [detailExport, setDetailexport] = useState([]);
  //  const [detailExport,setDetailexport]=useState([])
  const [message, setMessage] = useState();
  // const [lempar,setLempar]=useState()

  const [currentPage, setCurrentPage] = useState(0);

  // const [refresh,setRefresh]=useState('')

  // useEffect(()=>{

  //   const x=sessionStorage.getItem('keyItem')
  //   console.log('text x : '+x)

  // })
  useEffect(() => {
    const x = sessionStorage.getItem("keyItem");
    if (x === "lempar") {
      getdetailProd();
      setMessage("");
      sessionStorage.setItem("keyItem", "");
    }

    console.log(x);
  });

  useEffect(() => {
    getdetailProd();
  }, []);


const kosong=[{
  kode:'-',
  nama:'-',
  harga_jual:'---',
  harga_beli:'---',
  namafile:null,
  cabang:'-',

}]

  const getdetailProd = async () => {
    setIsloading(true);
    setIsLoadingPict(true);

    setTimeout(() => {
      $('#dataTable').DataTable().destroy();
      

       axios
      .get("https://dialey.angsoft.info/basereact/product.php")
      .then((res) => {


        if(res.data.length)
        {
        // setDetailProd({kode:'-',nama:'-',harga_jual:'-',harga_beli:'-',namafile:'-',cabang:'-'})
       
        setDetailProd(res.data)
        }else{
          setDetailProd(kosong)
          console.log(detailProd)
        }
        
        // setDetailProd(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  
      setIsloading(false);
      setIsLoadingPict(false);

    },1000);
 
  };


  useEffect(() => {
  
 
    // const timer = setTimeout(function () {
    // $('#dataTable').DataTable();// here you call the datatable logic 
  // }, 0);  // you see here , it run immediatly no ,dely !!!!but it solve it :D
  // return () => clearTimeout(timer);
  // $('#dataTable').DataTable().destroy();
//   $('#dataTable').DataTable( {
//     fixedHeader: true,
//     "bDestroy": true
// } );


 
  var table = $('#dataTable').DataTable( {

    lengthMenu: [
      [5,10, 25, 50, -1],
      [5,10, 25, 50, 'All']
  ],


      responsive: true,
      "bDestroy": true,
      fixedHeader: {
        footer: true
    },

    "columnDefs": [
      {"className": "dt-center", "targets": "_all"}
    ],
    
      scrollCollapse: true,
      'stripeClasses':['stripe1','stripe2']
      // scrollX: true,
      // scrollY: 400

  } );

 


  },[detailProd]);




  const url = "https://dialey.angsoft.info/upload_files/";
  const onDelete = async (id) => {
    const res = await axios.delete(
      "https://dialey.angsoft.info/basereact/product.php/" + id
    );
    try {
      setMessage(res.data.success);

      getdetailProd();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    const res = await axios.delete(
      "https://dialey.angsoft.info/basereact/product.php/" + id
    );
    try {
      setMessage(res.data.success);
      getdetailProd();
    } catch (err) {
      console.log(err);
    }
  };

  const delSwal = (id, nama) => {
    Swal.fire({
      title: "Delete this item  " + nama + " ?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else {
        Swal.fire("ok,cancelled");
      }
    });
  };

  const deldataAll = (id, nama) => {
    Swal.fire({
      title: "Clear All Data ?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        clearDataAll();
      } else {
        Swal.fire("ok,cancelled");
      }
    });
  };

  const awal = () => window.location.reload(true);

  
  const [cariFilter, setCariFilter] = useState(detailProd);
 
 

  const getexportproduct = async () => {
    setIsLoadingPict(true);
    const res = await  axios.get(
      "https://dialey.angsoft.info/basereact/getexport.php"
    );
    try {
      setDetailexport(res.data);
      console.log(detailExport)
    } catch (err) {
      console.log(err);
    }
    
    // setTimeout(()=>{
    //   if(detailExport.length===0)
    //   {
    //     getexportproduct();
    //   }
    // },1000)
    
  
  };

  const clearDataAll = async () => {
    const res = await axios.delete(
      "https://dialey.angsoft.info/basereact/getexport.php"
    );
    try {
      setMessage(res.data.success);

      getdetailProd();
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(()=>{
 

    if(detailExport.length)
    {
 
      const headings = [["kode", "nama", "harga_jual", "harga_beli", "cabang"]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, detailExport, { origin: "A2", skipHeader: true });
      utils.book_append_sheet(wb, ws, "Report");
      writeFile(wb, "ListProduct.xlsx");
   
  }  

  },[detailExport])

  const handleExport = () => {
    getexportproduct();

 
    
 
  //   if(detailExport.length)
  //   {
 
  //     const headings = [["kode", "nama", "harga_jual", "harga_beli", "cabang"]];
  //     const wb = utils.book_new();
  //     const ws = utils.json_to_sheet([]);
  //     utils.sheet_add_aoa(ws, headings);
  //     utils.sheet_add_json(ws, detailExport, { origin: "A2", skipHeader: true });
  //     utils.book_append_sheet(wb, ws, "Report");
  //     writeFile(wb, "ListProduct.xlsx");
   
  // }  
    
  setIsLoadingPict(false);
  };

  let componentRef = useRef();

  return (
    <>
 

      <div className="container">

      <Modal open={open} onClose={onCloseModal2} center>
        <div style={{width:'auto',height:'auto'}}>
        <ExcelModal />
        </div> 
        {/* <p>halo saya modal</p> */}
      </Modal>
      
        {openModal && <ExcelModal setOpenModal={setOpenModal} />}
        {/* {isloading?<Puffloader size='40px' color='blue' cssOverride={{marginBlock:'4rem'}} />:''} */}
        <div className="loaderlist"> {isloading?<Puffloader cssOverride={{width:'30%'}} />:''}</div>
        <h4 className="text-warning">{message}</h4>
        <div>
          <div className="basebutton" style={{alignItems:'center'}}>
            <div className="row">
              <div className="col">
                <div className="col-sm-12 d-flex  buttonall"  style={{verticalAlign:'middle',alignItems:'center',border:'solid 1px grey',padding:'5px',borderRadius:'10px',marginBottom:'10px',backgroundColor:'grey',color:'white'}}>
                  <h4 style={{ fontWeight: "bold",marginLeft:'10px' }}>Product List</h4>

               
                  <Link
                    to="/AddProduct"
                    className="btn btn-success col-sm-1 btn-sm"
                    id="sayalink"
                    style={{ marginLeft: "5px" }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FaRegCalendarPlus />
                    </span>{" "}
                    Add
                  </Link>



                  
                  <button
                  id="sayalink"
                    className="btn btn-success col-sm-1 btn-sm"
                    style={{ marginLeft: "5px" }}


                    onClick={()=>{onOpenModal();sessionStorage.setItem("keyItem", "");}}

                    // onClick={() => {
                    //   // setOpenModal(true);

                    //   onOpenModal;
                    //   // sessionStorage.setItem("keyItem", "");
                    // }}
                  >


{/* <button onClick={onOpenModal}>Open modal</button>
      <Modal open={open} onClose={onCloseModal} center>
        <ExcelModal />

        
      </Modal> */}


                    <span style={{ marginRight: "5px" }}>
                      <FaFileImport />
                    </span>{" "}
                    Import
                  </button>

                  <button
                  id="sayalink"
                    className="btn btn-success col-sm-1 btn-sm"
                    style={{ marginLeft: "5px" }}
                    onClick={()=>handleExport()}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FaFileExport />
                    </span>
                    {/* {isLoadingPict?<ScaleLoader color="blue" cssOverride={{height:'2px',width:'2px'}}/>:"Export"} */}
                    Export
                  </button>
                  <button
                  id="sayalink"
                    className="btn btn-success col-sm-1 btn-sm"
                    style={{ marginLeft: "5px" }}
                    onClick={deldataAll}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FaCut />
                    </span>{" "}
                    Clear Data
                  </button>
                  <Link
                  id="sayalink"
                  to='/PrintListProduct'
                    className="btn btn-success col-sm-1 btn-sm"
                    style={{ marginLeft: "5px" }}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FaPrint />
                    </span>{" "}
                    Print
                  </Link>
                  <button
                  id="sayalink"
                    className="btn btn-success col-sm-1 btn-sm"
                    style={{ marginLeft: "5px" }}
                    onClick={getdetailProd}
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FaCloudDownloadAlt />
                    </span>
                    Refresh 
                  </button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
{/* 
        // className="table table p-3 mb-2 bg-success bg-gradient */}
        
     
    <table id="dataTable" className="table table p-1 mb-2  bg-light bg-gradient "  >
    <thead>
    <tr ><th>Kode Item</th><th>Nama Item</th><th>Harga Jual</th><th>Harge Beli</th><th>Picture</th><th>Cabang</th><th>Action</th></tr>
    </thead>   
    <tbody>
        {detailProd.map((dprod,index)=>(

     <tr key={index} style={{fontSize:'12px',fontWeight:'bold'}}>
    <td >{dprod.kode}</td>
    <td>{dprod.nama}</td>
    <td style={{textAlign:'right'}}><NumericFormat value={dprod.harga_jual} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
    <td style={{textAlign:'right'}}><NumericFormat value={dprod.harga_beli} displayType={'text'} thousandSeparator={true} prefix={''} /></td>
    <td>{!dprod.namafile ? <img src={nopict} width='50px' height='50px'/> : isLoadingPict?<ScaleLoader size='1px'/>: <img src={'https://dialey.angsoft.info/upload_files/'+dprod.namafile} width='50px' height='50px'/>}</td>
    <td>
    {dprod.cabang}
      </td>
    <td style={{textAlign:'center'}} id='action'>
        <Link to={'/editproduct/'+dprod.id} ><span style={{fontSize:'16px'}}><AiFillEdit/></span></Link>
        <a href='#' onClick={()=>delSwal(dprod.id,dprod.nama)}><span style={{fontSize:'16px'}}><AiFillDelete/></span></a> 
        <Link to={'/EditProductPrint/'+dprod.id} ><span style={{fontSize:'16px'}}><FaPrint/></span></Link>
    </td>
    </tr> 

        ))
 
        }
    </tbody>

    <tfoot>
    <tr ><th>Kode Item</th><th>Nama Item</th><th>Harga Jual</th><th>Harge Beli</th><th>Picture</th><th>Cabang</th><th>Action</th></tr>
     </tfoot> 


    </table>

    
      </div>
    </>
  );
}

export default ListProduct;
